<template>
	<div>
		<ve-table
			:columns="columns"
			:rows="rows"
			:searchedOptions="false"
			:onRowDoubleClick="onRowDoubleClick"
			:onCellClick="onCellClick"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field === 'select'">
					<b-button class="is-small is-success">Select</b-button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</ve-table>
	</div>
</template>

<script>
import GetInvoicesToAddToJournalQuery from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/GetInvoicesToAddToJournalQuery.graphql'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import storeInvoiceJournal from '@/_srcv2/pages/accounting/page-create-invoice-journal/_shared/storeInvoiceJournal.js'
import Store from '@/store'
export default {
	name: 'InvoicesToAddToJournal',
	props: {
		addInvoiceToJournal: Function,
	},
	setup(props) {
		const { stateInvoiceJournal } = storeInvoiceJournal()
		const columns = reactive([
			{
				label: 'Select',
				field: 'select',
				width: '50px',
				sortable: false,
			},
			{
				label: 'Invoice Date',
				field: 'invoice_date',
				width: '125px',
			},
			{
				label: 'Invoice Number',
				field: 'invoice_number',
				width: '110px',
			},
			{
				label: 'Customer Title',
				field: 'customer.customer_title',
			},
			{
				label: 'Customer Nickname',
				field: 'customer.customer_nickname',
			},
			{
				label: 'Customer ID',
				field: 'customer.customer_id',
				width: '135px',
			},
		])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const rows = ref([])
		const { refetch } = useQuery(
			GetInvoicesToAddToJournalQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				invoice_date: stateInvoiceJournal.dateInvoicesToAddToJournal,
			}),
			options,
		)
		onMounted(() => {
			refetch().then((result) => {
				rows.value = result.data.document_transactions
			})
		})
		// ------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				// stateInvoiceJournal.selectedInvoiceNumber = params.row.invoice_number
				// stateInvoiceJournal.selectedInvoiceOurCompany = params.row.our_company
				props.addInvoiceToJournal(
					params.row.invoice_number,
					params.row.our_company,
					params.row.invoice_date,
				)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('params.row', params.row)
		}
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
		}
	},
}
</script>

<style scoped></style>
