<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<div>
				<section
					style="
						background-color: aliceblue;
						padding: 20px;
						margin-bottom: 30px;
					"
				>
					<div class="text-bold text-red">
						Current report number is:&nbsp;{{ createdReportNumber }}
					</div>
					<section>
						<b-field class="margin-top-20" label="Select the report date">
							<b-datepicker
								v-model="selectedReportDate"
								:show-week-number="showWeekNumber"
								:locale="locale"
								placeholder="Click to select..."
								icon="calendar-today"
								trap-focus
								:first-day-of-week="firstDayOfTheWeek"
							>
							</b-datepicker>
						</b-field>
					</section>
					<b-button class="is-success margin-top-10" @click="getReportNumber">
						Get New Report Number
					</b-button>
					<div class="margin-top-30">
						<b-field label="Number to set report number">
							<b-input v-model="manuallySettledReportNumber"></b-input>
						</b-field>
					</div>
					<b-button class="is-danger margin-top-10" @click="setReportNumber">
						Set Report Number to which invoices to be added Manually
					</b-button>
				</section>
				<div
					style="
						margin-bottom: 50px;
						background-color: #c1c1c1;
						padding: 40px 20px 40px 20px;
					"
				>
					<div style="font-weight: bolder; color: forestgreen">
						Get Invoice Journal for selected period
					</div>
					<section>
						<b-field class="margin-top-20" label="Select the Beginning Date">
							<b-datepicker
								v-model="beginningDate"
								:show-week-number="showWeekNumber"
								:locale="locale"
								placeholder="Click to select..."
								icon="calendar-today"
								trap-focus
								:first-day-of-week="firstDayOfTheWeek"
							>
							</b-datepicker>
						</b-field>
						<b-field class="margin-top-20" label="Select the Ending Date">
							<b-datepicker
								v-model="endingDate"
								:show-week-number="showWeekNumber"
								:locale="locale"
								placeholder="Click to select..."
								icon="calendar-today"
								trap-focus
								:first-day-of-week="firstDayOfTheWeek"
							>
							</b-datepicker>
						</b-field>
						<b-field>
							<b-button
								class="is-success margin-top-10"
								@click="getMonthlyInvoicesToAddToJournal"
							>
								Get Monthly Invoices to add to Journal
							</b-button>
						</b-field>
					</section>
					<div class="margin-top-30">
						<ve-table
							:columns="columns"
							:rows="rows"
							:onRowDoubleClick="onRowDoubleClick"
							:onCellClick="onCellClick"
						>
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'select'">
									<b-button class="is-small is-success">Select</b-button>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</ve-table>
						<div class="margin-top-20">
							<b-button class="is-danger" @click="addInvoicesToJournal">
								Add Invoices to journal
							</b-button>
						</div>
					</div>
				</div>
				<div style="padding: 20px; background-color: #9c9c9c; margin-top: 30px">
					<div v-if="showGetReportData" class="margin-top-10">
						<get-invoice-journal-report-data />
					</div>
				</div>
			</div>
			<div style="background-color: white; padding: 20px" class="margin-top-30">
				<b-button
					class="is-success margin-bottom-10"
					@click="onAddShowSingleInvoice"
					v-if="!showAddSingleInvoice"
				>
					Add single invoice to journal
				</b-button>
				<section v-if="showAddSingleInvoice">
					<b-button class="is-primary margin-bottom-10" @click="onClose">
						X Close
					</b-button>
					<b-field label="Select invoice(s) date to add invoice(s) to journal">
						<b-datepicker
							v-model="selectedBeginningDate"
							:show-week-number="showWeekNumber"
							:locale="locale"
							placeholder="Click to select..."
							icon="calendar-today"
							trap-focus
							:first-day-of-week="firstDayOfTheWeek"
						>
						</b-datepicker>
					</b-field>
					<b-button class="is-success margin-top-10" @click="getData">
						Get invoices for the selected date
					</b-button>
					<div v-if="stateInvoiceJournal.showInvoicesToAddToJournal">
						<div class="text-bold text-purple margin-top-30">
							Select the invoice to add to journal
						</div>
						<invoices-to-add-to-journal
							class="margin-top-10"
							:key="stateInvoiceJournal.keyInvoicesToAddToJournal"
							:addInvoiceToJournal="addInvoiceToJournal"
						/>
					</div>
				</section>
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import GetMonthlyInvoicesToAddToJournalQuery from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/get-monthly-invoices/GetMonthlyInvoicesToAddToJournalQuery.graphql'
// ***************************************************************************************************
import InvoicesToAddToJournal from '@/_srcv2/pages/accounting/page-create-invoice-journal/subcomponents/InvoicesToAddToJournal.vue'
import AddInvoiceJournalHeadersMutation from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/AddInvoiceJournalHeadersMutation.graphql'
import SetIsAddedToJournalMutation from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/SetIsAddedToJournalMutation.graphql'
import SetInvoicesIsAddedToJournalMutation from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/get-monthly-invoices/SetInvoicesIsAddedToJournalMutation.graphql'
import GetInvoiceJournalHeadersQuery from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/GetInvoiceJounalHeadersQuery.graphql'
import InsertInvoiceBookKeepHeadersLinesMutation from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/InsertInvoiceBookkeepHeadersMutation.graphql'
import { ref, onMounted, reactive, computed } from '@vue/composition-api'
import { useMutation, useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import dateformat from 'dateformat'
import storeInvoiceJournal from '@/_srcv2/pages/accounting/page-create-invoice-journal/_shared/storeInvoiceJournal.js'
import GetInvoiceJournalReportData from '@/_srcv2/pages/accounting/page-create-invoice-journal/get-invoice-journal-report-data/GetInvoiceJournalReportData.vue'

export default {
	name: 'CreateInvoiceJournal',
	components: {
		GetInvoiceJournalReportData,
		InvoicesToAddToJournal,
	},
	setup() {
		const beginningDate = ref(new Date())
		const endingDate = ref(new Date())
		// Get invoices to add to journal
		const monthlyInvoiceQueryVariables = reactive({
			beginning_date: computed(() =>
				dateformat(beginningDate.value, 'yyyy-mm-dd'),
			),
			ending_date: computed(() => dateformat(endingDate.value, 'yyyy-mm-dd')),
		})
		const options2 = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchMonthlyInvoice } = useQuery(
			GetMonthlyInvoicesToAddToJournalQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				bd: monthlyInvoiceQueryVariables.beginning_date,
				ed: monthlyInvoiceQueryVariables.ending_date,
			}),
			options2,
		)
		const getMonthlyInvoicesToAddToJournal = () => {
			refetchMonthlyInvoice().then((result) => {
				console.log('getMonthlyInvoicesToAddToJournal result', result)
				rows.value = result.data.document_transactions
			})
		}
		// -----------------------------------------------------------------------------------
		const rows = ref([]) // ! invoices to be added
		const columns = reactive([
			{
				label: 'Invoice Date',
				field: 'invoice_date',
				width: '125px',
			},
			{
				label: 'Invoice Number',
				field: 'invoice_number',
				width: '110px',
			},
			{
				label: 'Customer Title',
				field: 'customer.customer_title',
			},
			{
				label: 'Customer Nickname',
				field: 'customer.customer_nickname',
			},
			{
				label: 'Customer ID',
				field: 'customer.customer_id',
				width: '135px',
			},
		])
		const onRowDoubleClick = (params) => {
			console.log('params.row', params.row)
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.column.field', params.column.field)
			}
		}
		// ------------------------------------------------------------------------------------
		const { mutate: setInvoicesIsAdded } = useMutation(
			SetInvoicesIsAddedToJournalMutation,
			() => ({
				variables: {
					our_company: Store.getters.getUserCurrentCompany,
					bd: monthlyInvoiceQueryVariables.beginning_date,
					ed: monthlyInvoiceQueryVariables.ending_date,
				},
			}),
		)
		const setInvoicesArray = (array) => {
			return new Promise((resolve, reject) => {
				if (array !== null || undefined) {
					inputArray.value = mappedData(array)
					resolve(inputArray.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}

		const addInvoicesToJournal = () => {
			console.log('addInvoicesToJournal is fired')
			console.log('rows.value', rows.value)
			console.log('mappedData(rows.value)', mappedData(rows.value))
			setInvoicesArray(rows.value).then((array) => {
				console.log('******************* array', array)
				addInvoiceBookKeepRecords().then(() => {
					setInvoicesIsAdded()
						.then(() => alert('Invoices added to journal'))
						.then(() => refetchMonthlyInvoice())
				})
			})
		}
		// ------------------------------------------------------------------------------------
		const selectedReportDate = ref(new Date())
		// -------------------------------------------------------------------------------------
		const { mutate: AddInvoiceJournalHeaders, onDone } = useMutation(
			AddInvoiceJournalHeadersMutation,
			() => ({
				variables: {
					report_date: qVariables.report_date,
					our_company: qVariables.our_company,
				},
			}),
		)
		onDone((result) => {
			alert(
				`Invoice Journal Report header is created Report No: ${result.data.insert_invoice_journal_headers.returning[0].report_number}`,
			)
		})
		const getReportNumber = () => {
			console.log('getReportNumber is fired')
			AddInvoiceJournalHeaders().then((result) => {
				reportNumber.value =
					result.data.insert_invoice_journal_headers.returning[0].report_number
				stateInvoiceJournal.reportNumber = reportNumber.value
			})
		}
		// ----------------------------------------------------------------------------------
		const manuallySettledReportNumber = ref('')
		const setReportNumber = () => {
			console.log(
				'manuallySettledReportNumber',
				manuallySettledReportNumber.value,
			)
			reportNumber.value = manuallySettledReportNumber.value
			stateInvoiceJournal.reportNumber = reportNumber.value
		}
		// ------------------------------------------------------------------------------------
		const showAddSingleInvoice = ref(false)
		const onAddShowSingleInvoice = () => {
			showAddSingleInvoice.value = true
		}
		const onClose = () => {
			showAddSingleInvoice.value = false
		}
		// ************************************************************************************
		const selectedBeginningDate = ref(new Date())
		const showWeekNumber = ref(true)
		const firstDayOfTheWeek = ref(1)
		const locale = 'sv-SE'
		const createdReportNumber = computed(() => stateInvoiceJournal.reportNumber)
		onMounted(() => {
			selectedBeginningDate.value = new Date()
			showGetReportData.value = true
			showAddInvoiceJournal.value = true
		})
		const qVariables = reactive({
			beginning_date: computed(() =>
				dateformat(selectedBeginningDate.value, 'yyyy-mm-dd'),
			),
			report_date: computed(() =>
				dateformat(selectedReportDate.value, 'yyyy-mm-dd'),
			),
			our_company: Store.getters.getUserCurrentCompany,
			invoice_number: '',
		})
		// ---------------------------------------------------------------
		const variableOurCompany = ref('')
		const variableInvoiceNumber = ref('')
		const variableInvoiceDate = ref('')
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch } = useQuery(
			GetInvoiceJournalHeadersQuery,
			() => ({
				our_company: variableOurCompany.value,
				invoice_number: variableInvoiceNumber.value,
			}),
			options,
		)
		// ****************************************************
		const getData = () => {
			console.log('getData is fired')
			// refetch().then((result) => console.log('****** result', result))
			stateInvoiceJournal.dateInvoicesToAddToJournal = qVariables.beginning_date
			stateInvoiceJournal.showInvoicesToAddToJournal = true
			stateInvoiceJournal.keyInvoicesToAddToJournal =
				stateInvoiceJournal.keyInvoicesToAddToJournal + 1
		}
		// ************************************************************
		const getLines = (
			arr,
			debitCreditValues,
			invoiceExchangeUnit,
			invoiceExchangeRate,
			invoiceType,
		) => {
			console.log('arr', arr)
			// console.log('debitCreditValues', debitCreditValues)
			// console.log('invoiceExchangeUnit', invoiceExchangeUnit)
			// console.log('invoiceExchangeRate', invoiceExchangeRate)
			// console.log('invoiceType', invoiceType)
			// todo create invoiceBookKeepLinesArr to add book keep lines to database
			let invoiceBookKeepLinesArr = []
			// todo --------------------------------------------------------------------
			// todo Create customer debit/credit line & rounding line
			// ** calculate to pay and rounding ---------------------------------------
			const invoiceToPaySek = ref(0)
			const exc10000 = Math.round(invoiceExchangeRate * 10000)
			console.log('*********** exc10000', exc10000)
			const linesTotalExc =
				debitCreditValues.line_price_total_credit_exchange +
				debitCreditValues.vat_credit_exchange
			console.log('*********** linesTotalExc', linesTotalExc)
			// ! ------------------------------------------------------------------------
			const invoiceToPayExc = Math.round(linesTotalExc / 100)
			console.log('*-*-*- invoiceToPayExc', invoiceToPayExc)
			// ----------------------------------------------------
			invoiceToPaySek.value = ((invoiceToPayExc / exc10000) * 10000).toFixed(2)
			console.log('*-*-*- invoiceToPaySek.value', invoiceToPaySek.value)
			const customerDebitCredit = Math.round(invoiceToPaySek.value * 100)
			console.log('*-*-*- customerDebitCredit', customerDebitCredit)
			// ** push customer account ---------------------------------------
			if (customerDebitCredit > 0) {
				console.log('customerDebit is pushed')
				invoiceBookKeepLinesArr.push({
					account_id: '1510',
					account_name: 'Kundfordringar',
					credit: 0,
					debit: customerDebitCredit,
				})
			} else if (customerDebitCredit < 0) {
				console.log('customerCredit is pushed')
				invoiceBookKeepLinesArr.push({
					account_id: '1510',
					account_name: 'Kundfordringar',
					credit: customerDebitCredit * -1,
					debit: 0,
				})
			}
			// ** calculate rounding value ---------------------------------------
			const roundingSek = ref(0)
			const roundingExc = (invoiceToPayExc * 100 - linesTotalExc) / 100
			console.log('*-*-*- roundingExc', roundingExc)
			roundingSek.value = ((roundingExc / exc10000) * 10000).toFixed(2)
			console.log('*-*-*- roundingSek.value', roundingSek.value)
			const roundingDebitCredit = parseInt(roundingSek.value * 100)
			console.log('*-*-*- roundingDebitCredit', roundingDebitCredit)
			// ** push rounding account ---------------------------------------
			if (roundingDebitCredit > 0) {
				console.log('roundingCredit is pushed')
				invoiceBookKeepLinesArr.push({
					account_id: '3740',
					account_name: 'Öres- och kronutjämning',
					credit: roundingDebitCredit,
					debit: 0,
				})
				console.log('Öres- och kronutjämning ==> credit', roundingDebitCredit)
			} else if (roundingDebitCredit < 0) {
				console.log('roundingDebit is pushed')
				invoiceBookKeepLinesArr.push({
					account_id: '3740',
					account_name: 'Öres- och kronutjämning',
					credit: 0,
					debit: roundingDebitCredit * -1,
				})
				console.log(
					'Öres- och kronutjämning ==> debit',
					roundingDebitCredit * -1,
				)
			}
			console.log(
				'----------- After Customer and rounding',
				invoiceBookKeepLinesArr,
			)
			// todo --------------------------------------------------------------------
			// todo get V.A.T. lines --------------------------------------------
			// ** Get sale-invoice vat array
			let sales00 = 0
			let vat06 = 0
			let sales06 = 0
			let vat12 = 0
			let sales12 = 0
			let vat25 = 0
			let sales25 = 0
			console.log('invoiceType', invoiceType)
			if (invoiceType === 'Inland') {
				arr.forEach((item) => {
					if (item['vat_percent'] === 25) {
						vat25 = vat25 + item['vat_credit']
						sales25 = sales25 + item['line_price_total_credit']
					} else if (item['vat_percent'] === 12) {
						vat12 = vat12 + item['vat_credit']
						sales12 = sales12 + item['line_price_total_credit']
					} else if (item['vat_percent'] === 6) {
						vat06 = vat06 + item['vat_credit']
						sales06 = sales06 + item['line_price_total_credit']
					} else if (item['vat_percent'] === 0) {
						sales00 = sales00 + item['line_price_total_credit']
					}
				})
			}
			console.log(' ******* sales25', sales25, 'vat25', vat25)
			console.log(' ******* sales12', sales12, 'vat12', vat12)
			console.log(' ******* sales06', sales06, 'vat06', vat06)
			console.log(' ******* sales00', sales00)
			// todo get sales lines ----------------------------------------------------
			const salesDebitCredit = Math.round(
				customerDebitCredit - roundingDebitCredit,
			)
			console.log('*-*-*- salesDebitCredit', salesDebitCredit)
			if (invoiceType === 'Inside EU') {
				if (salesDebitCredit > 0) {
					console.log('Inside EU salesCredit is pushed')
					invoiceBookKeepLinesArr.push({
						account_id: '3108',
						account_name: 'Försäljning varor till annat EU-land, momsfri',
						credit: salesDebitCredit,
						debit: 0,
					})
				} else if (salesDebitCredit < 0) {
					console.log('Inside EU salesDebit is pushed')
					invoiceBookKeepLinesArr.push({
						account_id: '3108',
						account_name: 'Försäljning varor till annat EU-land, momsfri',
						credit: 0,
						debit: salesDebitCredit * -1,
					})
				}
			} else if (invoiceType === 'Outside EU') {
				if (salesDebitCredit > 0) {
					console.log('Outside EU salesCredit is pushed')
					invoiceBookKeepLinesArr.push({
						account_id: '3105',
						account_name: 'Försäljning varor till land utanför EU',
						credit: salesDebitCredit,
						debit: 0,
					})
				} else if (salesDebitCredit < 0) {
					console.log('Outside EU salesDebit is pushed')
					invoiceBookKeepLinesArr.push({
						account_id: '3108',
						account_name: 'Försäljning varor till annat EU-land, momsfri',
						credit: 0,
						debit: salesDebitCredit * -1,
					})
				}
			} else if (invoiceType === 'Inland') {
				// console.log(' ******* sales25', sales25, 'vat25', vat25)
				// console.log(' ******* sales12', sales12, 'vat12', vat12)
				// console.log(' ******* sales06', sales06, 'vat06', vat06)
				// console.log(' ******* sales00', sales00)
				if (sales25 > 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3010',
							account_name: 'Försäljning inom Sverige, 25 % moms',
							credit: sales25,
							debit: 0,
						},
						{
							account_id: '2610',
							account_name: 'Utgående moms på försäljning inom Sverige, 25 %',
							credit: vat25,
							debit: 0,
						},
					)
				}
				if (sales25 < 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3010',
							account_name: 'Försäljning inom Sverige, 25 % moms',
							credit: 0,
							debit: sales25 * -1,
						},
						{
							account_id: '2610',
							account_name: 'Utgående moms på försäljning inom Sverige, 25 %',
							credit: 0,
							debit: vat25 * -1,
						},
					)
				}
				if (sales12 > 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3002',
							account_name: 'Försäljning inom Sverige, 12 % moms',
							credit: sales12,
							debit: 0,
						},
						{
							account_id: '2621',
							account_name: 'Utgående moms på försäljning inom Sverige, 12 %',
							credit: vat12,
							debit: 0,
						},
					)
				}
				if (sales12 < 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3002',
							account_name: 'Försäljning inom Sverige, 12 % moms',
							credit: 0,
							debit: sales12 * -1,
						},
						{
							account_id: '2621',
							account_name: 'Utgående moms på försäljning inom Sverige, 12 %',
							credit: 0,
							debit: vat12 * -1,
						},
					)
				}
				if (sales06 > 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3003',
							account_name: 'Försäljning inom Sverige, 6 % moms',
							credit: sales06,
							debit: 0,
						},
						{
							account_id: '2631',
							account_name: 'Utgående moms på försäljning inom Sverige, 6 %',
							credit: vat06,
							debit: 0,
						},
					)
				}
				if (sales06 < 0) {
					invoiceBookKeepLinesArr.push(
						{
							account_id: '3003',
							account_name: 'Försäljning inom Sverige, 6 % moms',
							credit: 0,
							debit: sales06 * -1,
						},
						{
							account_id: '2631',
							account_name: 'Utgående moms på försäljning inom Sverige, 6 %',
							credit: 0,
							debit: vat06 * -1,
						},
					)
				}
				if (sales00 > 0) {
					invoiceBookKeepLinesArr.push({
						account_id: '3004',
						account_name: 'Försäljning inom Sverige, momsfri',
						credit: sales00,
						debit: 0,
					})
				}
				if (sales00 < 0) {
					invoiceBookKeepLinesArr.push({
						account_id: '3004',
						account_name: 'Försäljning inom Sverige, momsfri',
						credit: 0,
						debit: sales00 * -1,
					})
				}
			}
			// todo --------------------------------------------------------------------
			console.log(
				'----------- At the end of function before return',
				invoiceBookKeepLinesArr,
			)
			return invoiceBookKeepLinesArr
		}
		// todo push lines ------------------------------------------
		// ** the end of push to invoice book keep lines array
		//  todo end --------------------------------------------------------------------------
		const mappedData = (array) =>
			array.map((item) => {
				const debitCreditValues =
					item.doc_tr_goods_tr_invoice_num_rel_aggregate.aggregate.sum
				const vatArray = item.doc_tr_goods_tr_invoice_num_rel_aggregate.nodes
				// const vatArray = vatNodes ? vatNodes : []
				console.log('***************** item', item)
				console.log('***************** vatArray', vatArray)
				console.log('***************** debitCreditValues', debitCreditValues)
				const linesArray = getLines(
					vatArray,
					debitCreditValues,
					item.invoice_exchange_unit,
					item.invoice_exchange_rate,
					item.invoice_type,
				)
				console.log('***************** linesArray', linesArray)
				return {
					book_keep_date: item.invoice_date,
					customer_or_supplier_id: item.customer.customer_id,
					customer_or_supplier_title: item.customer.customer_title,
					invoice_date: item.invoice_date,
					invoice_due_date: item.invoice_due_date,
					invoice_exchange_rate: item.invoice_exchange_rate,
					invoice_exchange_unit: item.invoice_exchange_unit,
					invoice_number: item.invoice_number,
					line_price_total_credit: debitCreditValues.line_price_total_credit,
					line_price_total_credit_exchange:
						debitCreditValues.line_price_total_credit_exchange,
					our_company: item.our_company,
					report_number: reportNumber.value,
					report_type: 'Faktura Journal',
					vat_credit: debitCreditValues.vat_credit,
					vat_credit_exchange: debitCreditValues.vat_credit_exchange,
					invoice_book_keep_lines_rel: {
						data: linesArray,
					},
				}
			})
		// -------------------------------------------------------------------------------
		const inputArray = ref([])
		const {
			mutate: addInvoiceBookKeepRecords,
			onDone: onDoneAddInvoiceBookKeepRecords,
			onError: onErrorAddInvoiceBookKeepRecords,
		} = useMutation(InsertInvoiceBookKeepHeadersLinesMutation, () => ({
			variables: {
				input: inputArray.value,
			},
		}))
		onDoneAddInvoiceBookKeepRecords((result) => {
			alert('Records are added to db:', result)
		})
		onErrorAddInvoiceBookKeepRecords((err) => {
			alert('Error:', err)
		})
		// ---------------------------------------------------------------------
		const { mutate: setIsAdded } = useMutation(
			SetIsAddedToJournalMutation,
			() => ({
				variables: {
					our_company: variableOurCompany.value,
					invoice_number: variableInvoiceNumber.value,
				},
			}),
		)
		// -----------------------------------------------------------------------------------------------
		// ** Add single Invoice ****************************************************************************
		const { stateInvoiceJournal } = storeInvoiceJournal()
		const showGetReportData = ref(true)
		const showAddInvoiceJournal = ref(true)
		const reportNumber = ref(null)
		const setMutationVariables = (invoiceNumber, ourCompany, invoiceDate) => {
			return new Promise((resolve, reject) => {
				const condition = true
				if (condition) {
					variableOurCompany.value = ourCompany
					variableInvoiceNumber.value = invoiceNumber
					variableInvoiceDate.value = invoiceDate
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// When select invoice in InvoicesToAddToJournal component =>
		const addInvoiceToJournal = (invoiceNumber, ourCompany, invoiceDate) => {
			setMutationVariables(invoiceNumber, ourCompany, invoiceDate)
				.then(() => {
					console.log('variableOurCompany.value', variableOurCompany.value)
					console.log(
						'variableInvoiceNumber.value',
						variableInvoiceNumber.value,
					)
				})
				.then(() => {
					addHeader()
				})
		}
		const addHeader = () => {
			refetch()
				.then((result) => {
					console.log('result', result)
					inputArray.value = mappedData(result.data.document_transactions)
					console.log('inputArray.value', inputArray.value)
				})
				.then(() => {
					console.log('from addHeader inputArray.value', inputArray.value)
					addInvoiceBookKeepRecords()
						.then(() => {
							setIsAdded()
						})
						.then(() => {
							// stateInvoiceJournal.reportNumber = reportNumber.value
							// showGetReportData.value = true
							// showAddInvoiceJournal.value = true
							stateInvoiceJournal.keyInvoicesToAddToJournal =
								stateInvoiceJournal.keyInvoicesToAddToJournal + 1
						})
				})
		}
		return {
			beginningDate,
			endingDate,
			getMonthlyInvoicesToAddToJournal,
			columns,
			rows,
			onRowDoubleClick,
			onCellClick,
			addInvoicesToJournal,
			showAddSingleInvoice,
			onClose,
			onAddShowSingleInvoice,
			//***********************************
			getReportNumber,
			setReportNumber,
			manuallySettledReportNumber,
			addHeader,
			showWeekNumber,
			selectedBeginningDate,
			selectedReportDate,
			locale,
			firstDayOfTheWeek,
			showGetReportData,
			showAddInvoiceJournal,
			createdReportNumber,
			stateInvoiceJournal,
			addInvoiceToJournal,
			getData,
			// *****
			mappedData,
			addInvoiceBookKeepRecords,
			setIsAdded,
		}
	},
}
</script>

<style scoped></style>
