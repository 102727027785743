<template>
	<div>
		<b-button class="is-info margin-bottom-10" @click="getReportData">
			Show Invoice Journal Report's Current State
		</b-button>
	</div>
</template>

<script>
import GetInvoiceJournalReportQuery from '@/_srcv2/pages/accounting/page-create-invoice-journal/graphql/GetInvoiceJournalReportQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import { reactive } from '@vue/composition-api'
import storeInvoiceJournal from '@/_srcv2/pages/accounting/page-create-invoice-journal/_shared/storeInvoiceJournal.js'
import storeShared from '@/_srcv2/pages/_pages-store/storeShared.js'
import Store from '@/store'
import Router from '@/router'
import storeAccounting from '@/_srcv2/pages/accounting/accounting-store/storeAccounting.js'

export default {
	name: 'GetInvoiceJournalReportData',
	setup() {
		const { stateInvoiceJournal } = storeInvoiceJournal()
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch } = useQuery(
			GetInvoiceJournalReportQuery,
			() => ({
				report_number: stateInvoiceJournal.reportNumber,
			}),
			options,
		)
		// --------------------------------------------------------------------------------------------------
		const {
			getNewDateWithTime,
			numberFromDataBaseToTable,
			roundedCurrencyAddedNumber,
		} = storeShared()
		// ------------------------------------------------------------------------------------------------------
		// const reportData = reactive({
		//   beginningDate: new Date(),
		//   endingDate: new Date(),
		//   tableLinesSubLines: [],
		//   countTransactions: 0,
		//   creditAmount: 0,
		//   creditExchangeAmount: 0,
		//   coverLines: [],
		//   coverDebitTotals: 0,
		//   coverCreditTotals: 0,
		//   docDefinition: [],
		// })
		// Map the data to consume in the report
		const getInvoiceExchangeAmount = (
			invoiceExchangeUnit,
			linePriceTotalCredit,
			linePriceTotalCreditExchange,
			vat,
			item,
		) => {
			console.log('**********************************************')
			console.log('item', item)
			if (invoiceExchangeUnit !== 'kr.') {
				return Math.round(linePriceTotalCreditExchange / 100) * 100
			} else {
				return Math.round((linePriceTotalCredit + vat) / 100) * 100
			}
		}
		const getInvoiceSekAmount = (
			invoiceExchangeUnit,
			invoiceExchangeRate,
			linePriceTotalCredit,
			linePriceTotalCreditExchange,
			vat,
		) => {
			if (invoiceExchangeUnit !== 'kr.') {
				const invoiceToPayExc = Math.round(linePriceTotalCreditExchange / 100)
				const exc10000 = invoiceExchangeRate * 10000
				return ((invoiceToPayExc / exc10000) * 10000 * 100).toFixed(0)
			} else {
				return Math.round((linePriceTotalCredit + vat) / 100) * 100
			}
		}
		const mapReportData = (qResult) => {
			console.log('qResult------------------------', qResult)
			return new Promise((resolve, reject) => {
				if (qResult !== null || undefined) {
					// map
					const mapSubLines = (array) => {
						return array.map((item) => {
							return {
								account: item.account_id + ' ' + item.account_name,
								credit: item.credit,
								debit: item.debit,
							}
						})
					}
					reportData.tableLinesSubLines =
						qResult.data.invoice_journal_headers_by_pk.invoice_book_keep_headers_rel.map(
							(item) => {
								return {
									date: item.invoice_due_date, // *******
									transactionType: item.invoice_date, // *****
									companyId: item.customer_or_supplier_id,
									companyTitle: item.customer_or_supplier_title,
									documentNumber: item.invoice_number,
									exchangeRate: item.invoice_exchange_rate,
									currency: item.invoice_exchange_unit,
									credit: getInvoiceSekAmount(
										item.invoice_exchange_unit,
										item.invoice_exchange_rate,
										item.line_price_total_credit,
										item.line_price_total_credit_exchange,
										item.vat_credit,
									),
									// item.line_price_total_credit,
									creditExchange: getInvoiceExchangeAmount(
										item.invoice_exchange_unit,
										item.line_price_total_credit,
										item.line_price_total_credit_exchange,
										item.vat_credit,
										item,
									), // item.line_price_total_credit_exchange,
									subLines: mapSubLines(item.invoice_book_keep_lines_rel),
								}
							},
						)
					reportData.countTransactions = ''
					reportData.creditAmount = ''
					reportData.creditExchangeAmount = ''
					reportData.coverLines = qResult.data.invoice_book_keep_lines.map(
						(item) => {
							return {
								account: item.account_id + ' ' + item.account_name,
								credit:
									item.invoice_book_keep_lines_self_relation_aggregate.aggregate
										.sum.credit,
								debit:
									item.invoice_book_keep_lines_self_relation_aggregate.aggregate
										.sum.debit,
							}
						},
					)
					reportData.coverCreditTotals =
						qResult.data.invoice_book_keep_lines_aggregate.aggregate.sum.credit
					reportData.coverDebitTotals =
						qResult.data.invoice_book_keep_lines_aggregate.aggregate.sum.debit
					reportHeaders.reportNumber =
						qResult.data.invoice_journal_headers_by_pk.report_number
					reportHeaders.reportDate =
						qResult.data.invoice_journal_headers_by_pk.report_date
					reportHeaders.ourCompany =
						qResult.data.invoice_journal_headers_by_pk.our_company
					reportHeaders.reportType = 'Fakturajournal nr.'
					console.log('-----------------------------------------------')
					console.log('reportData', reportData)
					resolve({
						tableLinesSubLines: reportData.tableLinesSubLines,
						countTransactions: reportData.countTransactions,
						creditAmount: reportData.creditAmount,
						creditExchangeAmount: reportData.creditExchangeAmount,
						coverLines: reportData.coverLines,
						coverDebitTotals: reportData.coverDebitTotals,
						coverCreditTotals: reportData.coverCreditTotals,
					})
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ---------------------------------------------------------------------------------------------
		// Report Headers
		// const reportHeaders = reactive({
		//   reportDate: '',
		//   reportType: 'Fakturajournal nr. ',
		//   reportNumber: 0,
		//   ourCompany: '',
		//   coverTitle: 'Bokföringsunderlag',
		// })
		// ----------------------------------------------------------------------------------------------
		// Create docDefinition for pdfMake
		const createDocDefinition = (reportData) => {
			console.log(
				'from the beginning of createDocDefinition reportData',
				reportData,
			)
			return new Promise((resolve, reject) => {
				if (reportData !== null || undefined) {
					const data = {
						headerLeft: reportHeaders.ourCompany, // Store.getters.getCurrentCompanyTitle,
						// headerCenter: 'myCenterHeader',
						headerRight: `Utskrivet: ${getNewDateWithTime()}`,
						footerLeft: reportHeaders.ourCompany, // Store.getters.getCurrentCompanyTitle,
						footerCenter: 'myCenterFooter',
						// footerRight: 'myRightFooter',
						watermark: Store.getters.getWatermark,
						page: 'sida (',
						of: ') av ',
						// Cover ----------------------------------
						coverTitle: reportHeaders.coverTitle,
						accountTag: 'Konto',
						debitTag: 'Debet',
						creditTag: 'Kredit',
						footerTag: 'Omslutning',
						// Report
						invoiceNumberTag: 'Fakturanr.',
						customerNumberNameTag: 'Kundnr. Kundnamn.',
						paymentTypeTag: 'Faktdat',
						paymentDateTag: 'Förfaldat',
						amountTag: 'Faktbel',
						paymentCurrencyTag: 'Betalkurs',
						paymentInSekTag: 'Belopp kr.',
					}
					const docDefinition = reactive({
						pageSize: 'A4',
						pageOrientation: 'portrait',
						pageMargins: [50, 50, 40, 70],
						header: {
							columns: [
								{ text: data.headerLeft, style: 'documentHeaderLeft' },
								// { text: data.headerCenter, style: "documentHeaderCenter" },
								{ text: data.headerRight, style: 'documentHeaderRight' },
							],
						},
						// footer: {
						//   columns: [
						//     { text: data.footerLeft, style: "documentFooterLeft" },
						//     { text: data.footerCenter, style: "documentFooterLineCenter" },
						//     { text: data.footerCenter, style: "documentFooterRight" }
						//   ]
						// },
						footer: function (currentPage, pageCount) {
							return {
								columns: [
									// {
									//   table: {
									//     widths: ['*'],
									//     body: [[' '], [' ']]
									//   },
									//   style: 'documentFooterLineLeft',
									//   layout: 'lightHorizontalLines'
									// },
									{ text: data.footerLeft, style: 'documentFooterLeft' },
									// {
									//   table: {
									//     widths: ['*'],
									//     body: [[' '], [' ']]
									//   },
									//   style: 'documentFooterLineCenter',
									//   layout: 'lightHorizontalLines'
									// },
									{
										text:
											data.page + currentPage.toString() + data.of + pageCount,
										style: 'documentFooterRight',
									},
								],
							}
						},
						// background: function() {
						//   return {
						//     image: data.watermark,
						//     opacity: 0.3,
						//     alignment: 'center',
						//     height: 264,
						//     margin: [0, (842 - 264) / 2, 0, 0]
						//   }
						// },
						background: [
							{
								image: data.watermark,
								opacity: 0.3,
								alignment: 'center',
								height: 264,
								margin: [0, (842 - 264) / 2, 0, 0],
							},
							{
								canvas: [
									{
										type: 'line',
										x1: 50,
										y1: 195,
										x2: 570,
										y2: 195,
										lineWidth: 1,
									},
								],
							},
						],
						content: [],
						styles: {
							// Document Header
							documentHeaderLeft: {
								fontSize: 10,
								margin: [50, 20, 5, 20],
								alignment: 'left',
							},
							documentHeaderCenter: {
								fontSize: 10,
								margin: [5, 5, 5, 40],
								alignment: 'center',
							},
							documentHeaderRight: {
								fontSize: 10,
								margin: [0, 20, 40, 20],
								alignment: 'right',
							},
							// Document Footer
							documentFooterLeft: {
								fontSize: 12,
								bold: true,
								margin: [50, 30, 5, 40],
								alignment: 'left',
							},
							documentFooterLineLeft: {
								fontSize: 10,
								margin: [50, 0, 5, 0],
								alignment: 'left',
							},
							documentFooterLineCenter: {
								fontSize: 10,
								margin: [0, 0, 5, 0],
								alignment: 'center',
							},
							documentFooterLineRight: {
								fontSize: 10,
								margin: [0, 0, 5, 50],
								alignment: 'right',
							},
							documentFooterRight: {
								fontSize: 10,
								margin: [5, 30, 40, 40],
								alignment: 'right',
							},
							// Document Title
							documentTitle: {
								fontSize: 22,
								bold: true,
								alignment: 'right',
								margin: [0, 0, 0, 15],
							},
							// Document Details
							documentSubTitle: {
								fontSize: 12,
								alignment: 'right',
							},
							documentSubValue: {
								fontSize: 12,
								alignment: 'right',
							},
							// Items Footer (Subtotal, Total, Tax, etc)
							itemsFooterSubTitle: {
								margin: [0, 5, 0, 0],
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							itemsFooterSubValue: {
								margin: [0, 5, 13, 0],
								bold: true,
								alignment: 'right', // center
								fontSize: 10,
							},
							itemsFooterTotalTitle: {
								margin: [0, 3, 0, 5],
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							itemsFooterTotalValue: {
								margin: [0, 3, 12, 5], // *******************
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							center: {
								alignment: 'center',
							},
							itemVat: {
								margin: [2, 5, 2, 5],
								alignment: 'right',
								fontSize: 10,
							},
							itemVatAmount: {
								margin: [0, 5, 14, 5],
								alignment: 'right',
								fontSize: 10,
							},
							paymentTableStyle: {
								margin: [0, 10, 0, 0],
							},
						},
						tableHeader: {
							bold: true,
							alignment: 'center',
							fontSize: 10,
						},
						defaultStyle: {
							columnGap: 20,
						},
						// Cover
						coverTitle: {
							fontSize: 18,
							bold: true,
							alignment: 'justify',
						},
						textInTable: {
							alignment: 'left',
						},
						numberInTable: {
							alignment: 'justify',
						},
						// Report
						reportTableHeader: {
							bold: true,
							alignment: 'left',
							fontSize: 10,
						},
					})
					// ********************** PUSH CONTENT BEGIN********************************************************
					// Cover Headers
					const coverHeader = {
						text: data.coverTitle,
						style: 'coverTitle',
						alignment: 'center',
						margins: [0, 0, 0, 40],
					}
					const coverSubHeaders = {
						columns: [
							{
								text: `Verifikationstext: ${reportHeaders.reportType} ${reportHeaders.reportNumber}`,
								alignment: 'left',
							},
							{
								text: `Verifikationsdatum: ${reportHeaders.reportDate}`,
								alignment: 'right',
							},
						],
						margin: [0, 20, 0, 0],
					}
					const horizontalLine = {
						table: {
							widths: ['*'],
							body: [[' '], [' ']],
						},
						layout: 'lightHorizontalLines',
					}
					// Cover Table - Lines - table footer
					let coverTableHeaderRow = []
					const pushCoverTableHeaderRow = () => {
						coverTableHeaderRow.push([
							{
								text: data.accountTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.debitTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.creditTag,
								style: ['textInTable', 'center'],
							},
						])
					}
					pushCoverTableHeaderRow()
					const pushCoverTableHeader = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableHeaderRow,
							},
							layout: 'noBorders',
						})
					}
					let coverTableRows = []
					// push values to cover table ro coverTableRows array
					const pushRowsItemsLines = (coverTableLines) => {
						for (let item of coverTableLines) {
							coverTableRows.push([
								{
									text: item.account,
									alignment: 'left',
								},
								{
									text: numberFromDataBaseToTable(item.debit),
									alignment: 'right',
								},
								{
									text: numberFromDataBaseToTable(item.credit),
									alignment: 'right',
								},
							])
						}
					}
					pushRowsItemsLines(reportData.coverLines)
					console.log('after lines coverTableRows', coverTableRows)
					// push cover table
					const pushCoverTableLines = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableRows,
							},
							layout: 'noBorders',
						})
					}
					//
					let coverTableFooterRow = []
					const pushCoverTableFooterRow = () => {
						coverTableFooterRow.push([
							{
								text: data.footerTag,
								alignment: 'left',
							},
							{
								text: numberFromDataBaseToTable(reportData.coverDebitTotals),
								alignment: 'right',
							},
							{
								text: numberFromDataBaseToTable(reportData.coverCreditTotals),
								alignment: 'right',
							},
						])
					}
					pushCoverTableFooterRow()
					// push cover table
					const pushCoverTableFooter = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableFooterRow,
							},
							layout: 'noBorders',
							pageBreak: 'after',
						})
					}
					// Collected Payment Report
					// Report Headers
					const reportHeader = {
						text: `${reportHeaders.reportType} ${reportHeaders.reportNumber}`,
						style: 'coverTitle',
						alignment: 'center',
						margins: [0, 0, 0, 40],
					}
					const reportSubHeaders = {
						columns: [
							{
								text: '',
								alignment: 'left',
							},
							{
								text: `Journaldatum: ${reportHeaders.reportDate}`,
								alignment: 'right',
							},
						],
						margin: [0, 20, 0, 0],
					}
					// Report Table - Lines - table footer
					let reportTableHeaderFirstRow = []
					const pushReportTableHeaderFirstRow = () => {
						reportTableHeaderFirstRow.push([
							{
								text: data.invoiceNumberTag,
								style: ['textInTable', 'left'],
							},
							{
								text: data.customerNumberNameTag,
								style: ['textInTable', 'left'],
							},
							{
								text: '',
								style: ['textInTable', 'left'],
							},
						])
					}
					pushReportTableHeaderFirstRow()
					const pushReportTableHeaderFirst = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: [60, 150, '*'],
								style: 'reportTableHeader',
								body: reportTableHeaderFirstRow,
							},
							layout: 'noBorders',
						})
					}
					let reportTableHeaderSecondRow = []
					const pushReportTableHeaderSecondRow = () => {
						reportTableHeaderSecondRow.push([
							{
								text: '',
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentTypeTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentDateTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.amountTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentCurrencyTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentInSekTag,
								style: ['textInTable', 'center'],
							},
						])
					}
					pushReportTableHeaderSecondRow()
					const pushFirstRow = (item) => {
						// console.log('from pushFirstRow-----', item)
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 0,
								widths: [60, 450, '*'],
								style: 'tableHeader',
								body: [
									[
										{
											text: item.documentNumber,
										},
										{
											text: item.companyId + ' ' + item.companyTitle,
										},
									],
								],
							},
							layout: 'noBorders',
						})
					}
					const pushReportTableHeaderSecond = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: [80, 80, 80, '*', 80, 80],
								style: 'tableHeader',
								body: reportTableHeaderSecondRow,
							},
							layout: 'noBorders',
						})
					}
					const pushSecondRow = (item) => {
						// console.log('from pushSecondRow-----', item)
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 0,
								widths: [80, 80, 80, '*', 80, 80],
								style: 'tableHeader',
								body: [
									[
										{
											text: '',
										},
										{
											text: item.transactionType,
										},
										{
											text: item.date,
										},
										{
											text: roundedCurrencyAddedNumber(
												item.creditExchange,
												item.currency,
											),
											alignment: 'right',
										},
										{
											text: item.exchangeRate.toFixed(4),
											alignment: 'center',
										},
										{
											text: numberFromDataBaseToTable(item.credit),
											alignment: 'right',
										},
									],
								],
							},
							layout: 'noBorders',
						})
						for (let subLine of item.subLines) {
							pushSubLine(subLine)
						}
					}
					const pushSubLine = (subLine) => {
						// console.log('-------------subLine', subLine)
						docDefinition.content.push({
							table: {
								headerRows: 0,
								widths: [80, '*', 80, 80],
								style: 'tableHeader',
								body: [
									[
										{
											text: '',
											alignment: 'left',
										},
										{
											text: subLine.account,
											alignment: 'left',
										},
										{
											text: numberFromDataBaseToTable(subLine.debit),
											alignment: 'right',
										},
										{
											text: numberFromDataBaseToTable(subLine.credit),
											alignment: 'right',
										},
									],
								],
							},
							layout: 'noBorders',
						})
					}
					const pushReportTable = () => {
						for (let item of reportData.tableLinesSubLines) {
							pushFirstRow(item)
							pushSecondRow(item)
							docDefinition.content.push(horizontalLine)
							// pushSubTable(item)
						}
					}
					// const reportFooter = {
					//   alignment: 'justify',
					//   columns: [
					//     {
					//       text: '',
					//       alignment: 'left',
					//     },
					//     {
					//       text: '',
					//       alignment: 'right',
					//     },
					//     {
					//       text: '',
					//       alignment: 'left',
					//     },
					//     {
					//       text: numberFromDataBaseToTable(reportData.creditAmount),
					//       alignment: 'right',
					//     },
					//   ],
					// }
					// push
					docDefinition.content.push(coverHeader)
					docDefinition.content.push(coverSubHeaders)
					docDefinition.content.push(horizontalLine)
					pushCoverTableHeader()
					docDefinition.content.push(horizontalLine)
					pushCoverTableLines()
					docDefinition.content.push(horizontalLine)
					pushCoverTableFooter()
					docDefinition.content.push(reportHeader)
					docDefinition.content.push(reportSubHeaders)
					docDefinition.content.push(horizontalLine)
					pushReportTableHeaderFirst()
					pushReportTableHeaderSecond()
					docDefinition.content.push(horizontalLine)
					pushReportTable()
					// docDefinition.content.push(reportFooter)
					console.log('---------------------------------------')
					console.log('docDefinition', docDefinition)
					resolve(docDefinition)
				} else {
					const reason = new Error('docDefinition could not be created')
					reject(reason)
				}
			})
		}
		// -------------------------------------------------------------------------------------------
		// Store the docDefinition
		const storeDocDefinition = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					stateInvoiceJournal.documentDefinition = result
					reportData.docDefinition = result
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ---------------------------------------------------------------------------------------------
		const { reportHeaders, reportData } = storeAccounting()
		const getReportData = () => {
			console.log('getReportData is fired')
			refetch()
				.then((result) => {
					console.log('*****************result', result)
					mapReportData(result)
				})
				.then(() => createDocDefinition(reportData))
				.then((result) => storeDocDefinition(result))
				.then(() => Router.push({ name: 'CollectedPaymentsReport' }))
		}
		return {
			getReportData,
			stateInvoiceJournal,
		}
	},
}
</script>

<style scoped></style>
