import Vue from 'vue'
import VueCompositionApi, { reactive } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const stateInvoiceJournal = reactive({
	reportNumber: null,
	dateInvoicesToAddToJournal: '',
	keyInvoicesToAddToJournal: 1,
	showInvoicesToAddToJournal: false,
	selectedInvoiceNumber: '',
	selectedInvoiceOurCompany: '',
	documentDefinition: {},
})
export default function storeInvoiceJournal() {
	return {
		stateInvoiceJournal,
	}
}
